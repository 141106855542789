import styled from "styled-components";
import Features from "../../components/Features";

import imgFe1 from "../../../assets/icons/fe-1.svg";
import imgFe2 from "../../../assets/icons/fe-2.svg";
import imgFe3 from "../../../assets/icons/fe-3.svg";
import imgForFe from "../../../assets/imgs/for-fe-bg.png";

const Wrapper = styled.div`
    position: relative;
    .bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -1;
    }
`;

export default function ForFrontend() {
    return (
        <Wrapper>
            <img className="bg" src={imgForFe} alt="background" />
            <Features
                title="For frontend developers"
                items={[
                    {
                        title: "React & Vue integration",
                        icon: imgFe1,
                        subtitle: "Easily integrated with your favorite frontend library",
                    },
                    {
                        title: "Simple HTTP & WebSocket usage",
                        icon: imgFe2,
                        subtitle: "Ready to use HTTP and WebSocket clients",
                    },
                    {
                        title: "Auto generated data store & APIs",
                        icon: imgFe3,
                        subtitle: "Use less code for the same functionality",
                    },
                ]}
            />
        </Wrapper>
    );
}
