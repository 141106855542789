import { TranslationPartial } from "@clairejs/client";
import type { Trans } from "./Trans";

const vi: TranslationPartial<Trans> = {
    home: {
        contact_us: "Contact Us",
        get_started: "Get Started",
        sub_title: "Boost your performance as Javascript developer",
    },
};

export default vi;
