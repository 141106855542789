import styled from "styled-components";

import imgQuickDemo from "../../../assets/imgs/quick-start.png";

const Wrapper = styled.div`
    .title {
        color: rgba(4, 152, 194, 1);
        font-weight: bold;
        text-align: center;
        font-size: 2rem;
        margin-bottom: 34px;
    }

    .quick-demo {
        img {
            width: 100%;
            height: 377px;
            object-fit: cover;
        }
    }

    .description {
        max-width: 610px;
        margin: auto;
        margin-top: 21px;
        margin-bottom: 34px;
    }
`;

export default function QuickStart() {
    return (
        <Wrapper>
            <div className="title">Quick start</div>
            <div className="quick-demo containerx">
                <img src={imgQuickDemo} alt="quick" />
            </div>
            <div className="description containerx">
                Let's see how quick and simple it is using ClaireJs Framework
                to implement the basic endpoints for CRUD operations.
            </div>
        </Wrapper>
    );
}
