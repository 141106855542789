import styled from "styled-components";
import imgFb from "../../assets/icons/fb.svg";
import imgTw from "../../assets/icons/tw.svg";
import imgIn from "../../assets/icons/in.svg";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    a {
        padding: 8px;
    }
`;

const socials: {
    link: string;
    icon: any;
}[] = [
    { link: "https://linkedin.com", icon: imgIn },
    { link: "https://twitter.com", icon: imgTw },
    { link: "https://facebook.com", icon: imgFb },
];

export default function Socials() {
    return (
        <Wrapper className="social">
            {socials.map((s) => (
                <a key={s.link} target={"_blank"} href={s.link}>
                    <img src={s.icon} />
                </a>
            ))}
        </Wrapper>
    );
}
