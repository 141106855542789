import styled from "styled-components";
import Banner from "./sections/Banner";

import Typescript from "./sections/Typescript";
import QuickStart from "./sections/QuickStart";
import ForBackend from "./sections/ForBackend";
import ForFrontend from "./sections/ForFrontend";
import ForDevops from "./sections/ForDevops";
import ApiExplorer from "./sections/ApiExplorer";
import TrustedBy from "./sections/TrustedBy";
import Quotes from "./sections/Quotes";

const Wrapper = styled.div`
    flex: 1;

    .section {
        margin: 34px 0;
    }
`;

export default function PageContent() {
    const elements = [
        <Banner />,
        <ForBackend />,
        <Typescript />,
        <ForFrontend />,
        <QuickStart />,
        <ForDevops />,
        <ApiExplorer />,
        <TrustedBy />,
        // <Quotes />,
    ];
    return (
        <Wrapper>
            {elements.map((e) => (
                <div key={e.type.name} className={e.type.name === Banner.name ? "" : "section"}>
                    {e}
                </div>
            ))}
        </Wrapper>
    );
}
