import styled from "styled-components";

const Wrapper = styled.div`
    padding: 34px 0;
    .title {
        color: rgba(4, 152, 194, 1);
        font-weight: bold;
        text-align: center;
        font-size: 2rem;
        margin-bottom: 34px;
    }

    .items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;

        .feature-item {
            width: 267px;
            height: 267px;
            padding: 21px;
            border: 1px solid rgba(90, 202, 235, 1);
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            transition: 0.3s;

            &:hover {
                transform: scale(1.05);
            }

            .icon-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 21px;

                > div {
                    width: 89px;
                    height: 89px;
                    background-color: rgba(4, 152, 194, 0.15);
                    border-radius: 50%;
                    filter: blur(2px);
                }

                img {
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    object-fit: contain;
                }
            }

            .item-title {
                white-space: pre-wrap;
                font-weight: bold;
                font-size: 1.2rem;
                text-align: center;
                margin-bottom: 21px;
                height: 3rem;
            }
            .item-subtitle {
                font-size: 1rem;
            }
        }
    }
`;

interface Props {
    title: string;
    items: {
        icon: any;
        title: string;
        subtitle: string;
    }[];
}
export default function Features({ title, items }: Props) {
    return (
        <Wrapper>
            <div className="title">{title}</div>
            <div className="items containerx">
                {items.map((item) => (
                    <div className="feature-item" key={item.title}>
                        <div className="icon-wrapper">
                            <div />
                            <img src={item.icon} />
                        </div>
                        <div className="item-title">{item.title}</div>
                        <div className="item-subtitle">{item.subtitle}</div>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
}
