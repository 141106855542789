import { AbstractLogger, AccessToken } from "@clairejs/core";
import { AbstractTokenManager } from "./AbstractTokenManager";
import { DefaultHttpClient } from "./DefaultHttpClient";

export class RefreshHttpClient extends DefaultHttpClient {
    private refreshing = false;
    private refreshQueue: {
        resolver: () => void;
    }[] = [];

    public constructor(
        readonly apiServerUrl: string,
        readonly logger: AbstractLogger,
        readonly tokenManager: AbstractTokenManager,
        readonly maxRetryCount = 2,
        readonly delayMsBetweenRetry = 200,
    ) {
        super(apiServerUrl, logger, maxRetryCount, delayMsBetweenRetry);
    }

    protected async getRefreshedAccessToken(): Promise<AccessToken | undefined> {
        return undefined;
    }

    protected async resolveHeaders(headers?: object) {
        if (!headers || !Object.keys(headers).map((k) => k.toLowerCase().includes("authorization"))) {
            const accessToken = await this.tokenManager.getAccessToken();
            //-- 30s buffer
            if (accessToken && accessToken.expiration < Date.now() + 30000) {
                await this.refreshToken(accessToken);
            }
        }

        const accessToken = await this.tokenManager.getAccessToken();
        const userHeaders = await super.resolveHeaders(headers);
        return { authorization: accessToken?.token || "", ...userHeaders };
    }

    async refreshToken(oldToken?: AccessToken) {
        if (this.refreshing) {
            this.logger.debug("Awaiting refresh token, queued");
            await new Promise<void>((resolve) => {
                this.refreshQueue.push({ resolver: resolve });
            });
        } else {
            oldToken = oldToken || (await this.tokenManager.getAccessToken());
            //-- call to api server to refresh token
            if (!oldToken || !oldToken.refreshToken) {
                //-- there is no refresh token to refresh
                return;
            }

            try {
                this.refreshing = true;
                const newToken = await this.getRefreshedAccessToken();
                await this.tokenManager.setAccessToken(newToken);
                this.logger.debug("Access token was refreshed");
            } catch (err) {
                //-- cannot refresh access token, clear old token
                this.logger.debug("Cannot refresh access token, clear local token");
                await this.tokenManager.setAccessToken();
            } finally {
                this.refreshing = false;
                for (const refresh of this.refreshQueue) {
                    refresh.resolver();
                }
                //-- clear queue
                this.refreshQueue = [];
            }
        }
    }
}
