import {
    AbstractModel,
    Constructor,
    CreateManyRequestBody,
    CreateManyResponseBody,
    deepMerge,
    GetManyQueries,
    GetManyResponseBody,
    Identifiable,
    UpdateManyBody,
    UpdateManyQueries,
    UpdateManyResponse,
} from "@clairejs/core";
import { AbstractHttpClient } from "./AbstractHttpClient";

export const stringifyQueries = (queries: Record<string, any>) => {
    const keys = Object.keys(queries);
    return keys.reduce(
        (collector, key) =>
            collector + `&${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(queries[key]))}`,
        "",
    );
};

export const removeInstances = <T extends Identifiable>(target: T[], source: T[]) => {
    const result = target.slice();
    for (const instance of source) {
        const index = result.findIndex((i) => !!i.id && !!instance.id && i.id === instance.id);
        if (index >= 0) {
            result.splice(index, 1);
        }
    }
    return result;
};

export const mergeInstances = <T extends Identifiable>(model: Constructor<T>, target: T[], source: Partial<T>[]) => {
    const result = target.slice();
    for (const instance of source) {
        const index = result.findIndex((i) => !!i.id && !!instance.id && i.id === instance.id);
        if (index < 0) {
            result.push(Object.assign(new model(), instance));
        } else {
            deepMerge(result[index], instance);
        }
    }
    return result;
};

export class CrudApi<T extends AbstractModel> {
    private dirty = true;

    constructor(readonly model: Constructor<T>, readonly httpClient: AbstractHttpClient) {}

    protected getEndpointBaseUrl() {
        return `/${this.model.name.toLowerCase()}`;
    }

    async getMany(queries?: GetManyQueries<T>, useCache = true): Promise<GetManyResponseBody<T>> {
        const noCache = !useCache || this.dirty;
        const result = await this.httpClient.get(
            `${this.getEndpointBaseUrl()}?${stringifyQueries(queries || {})}`,
            noCache
                ? {
                      "cache-control": "no-cache",
                  }
                : undefined,
        );
        this.dirty = false;
        return result;
    }

    async updateMany(body: UpdateManyBody<T>, queries?: UpdateManyQueries<T>): Promise<UpdateManyResponse<T>> {
        this.dirty = true;
        return await this.httpClient.put(`${this.getEndpointBaseUrl()}?${stringifyQueries(queries || {})}`, body);
    }

    async deleteMany(queries?: UpdateManyQueries<T>): Promise<UpdateManyResponse<T>> {
        this.dirty = true;
        return await this.httpClient.delete(`${this.getEndpointBaseUrl()}?${stringifyQueries(queries || {})}`);
    }

    async createMany(body: CreateManyRequestBody<T>): Promise<CreateManyResponseBody<T>> {
        this.dirty = true;
        return await this.httpClient.post(`${this.getEndpointBaseUrl()}`, body);
    }
}
