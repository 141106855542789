import { DisconnectionHandler, IClientSocket, PlainMessageHandler, ReconnectionHandler } from "@clairejs/core";
import { DefaultClientSocketManager } from "./DefaultClientSocketManager";

export class DefaultClientSocket implements IClientSocket {
    constructor(readonly socketManager: DefaultClientSocketManager) {}

    joinChannels(channels: string[]): void {
        this.socketManager.subChannels(this, channels);
    }

    leaveChannels(channels: string[]): void {
        this.socketManager.unsubChannels(this, channels);
    }

    close(): void {
        this.socketManager.removeSocket(this);
    }

    onReconnect(handler: ReconnectionHandler): void {
        this.socketManager.registerReconnectionHandler(this, handler);
    }

    onDisconnect(handler: DisconnectionHandler): void {
        this.socketManager.registerDisconnectionHandler(this, handler);
    }

    onMessage(handler: PlainMessageHandler): void {
        this.socketManager.registerMessageHandler(this, handler);
    }

    send(message: any, channel?: string): void {
        this.socketManager.sendPlainMessageToChannel(message, channel);
    }
}
