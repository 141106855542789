import styled from "styled-components";
import Features from "../../components/Features";

import be1 from "../../../assets/icons/be-1.svg";
import be2 from "../../../assets/icons/be-2.svg";
import be3 from "../../../assets/icons/be-3.svg";

const Wrapper = styled.div``;

export default function ForBackend() {
    return (
        <Wrapper>
            <Features
                title="For backend developers"
                items={[
                    {
                        title: "Auto generated APIs",
                        icon: be1,
                        subtitle: "Get your endpoints up and ready in several seconds",
                    },
                    {
                        title: "Typesafe ORM \n& auto migrations",
                        icon: be2,
                        subtitle: "Use same data logic for both SQL and NoSQL databases",
                    },
                    {
                        title: "Integrated security mechanisms",
                        icon: be3,
                        subtitle: "Simply protect and manage access to your APIs",
                    },
                ]}
            />
        </Wrapper>
    );
}
