import { TranslationPartial } from "@clairejs/client";
import type { Trans } from "./Trans";

const vi: TranslationPartial<Trans> = {
    home: {
        contact_us: "Liên hệ",
        get_started: "Bắt đầu ngay",
        sub_title: "Framework dành cho fullstack Javascript developer",
    },
};

export default vi;
