import styled from "styled-components";
import { I18n } from "@clairejs/react";

import PageContent from "./home/PageContent";
import PageFooter from "./home/PageFooter";
import PageHeader from "./home/PageHeader";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const translations = {
    vi: require("../assets/translations/vi").default,
    en: require("../assets/translations/en").default,
};

export default function App() {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        //-- listen to window scroll event
        const listener = () => {
            if (window.scrollY > 0 && !scroll) {
                setScroll(true);
            } else if (window.scrollY === 0 && scroll) {
                setScroll(false);
            }
        };
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    }, [scroll]);

    return (
        <I18n translations={translations}>
            <Wrapper>
                <PageHeader float={scroll} />
                <PageContent />
                <PageFooter />
            </Wrapper>
        </I18n>
    );
}
