import styled from "styled-components";
import UnsplashImg from "../../components/UnsplashImg";
import imgType from "../../../assets/icons/typescript.svg";
import imgTypeBg from "../../../assets/imgs/typescript-bg.png";

import { useConfig } from "../../../utils/resolver";

const Wrapper = styled.div`
    padding: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-img {
        width: 377px;
        margin-right: 34px;
    }

    .type-note {
        flex: 1;

        .type-name {
            display: flex;
            align-items: center;

            .type {
                font-weight: bold;
                font-size: 1.5rem;
            }

            img {
                margin-left: 5px;
                margin-bottom: 21px;
                width: 21px;
                height: 21px;
                object-fit: contain;
            }
        }

        .type-sub {
            margin-top: 13px;
        }
    }
`;

export default function Typescript() {
    const config = useConfig();

    return (
        <Wrapper className="typescript containerx">
            <UnsplashImg
                enabled={false}
                className="left-img"
                storageKey="IMG_TYPESCRIPT"
                fallbackImg={imgTypeBg}
                retentionDurationH={24}
                apiKey={config.UNSPLASH_API_KEY}
                keyword="programming workspace"
                orientation="landscape"
            />
            <div className="type-note">
                <div className="type-name">
                    <div className="type">100% Typescript</div>
                    <img src={imgType} />
                </div>
                <div className="type-sub">
                    ClaireJs libraries are developed in Typescript, provide you all necesary documentation for an
                    exciting and confident programming expericence.
                    <br />
                    <br />
                    All core types are exported, so you can extend and enhance the functionality of the framework
                    whenever you want.
                </div>
            </div>
        </Wrapper>
    );
}
