export interface RequestOptions {
    withCredentials?: boolean;
}

export abstract class AbstractHttpClient {
    constructor(readonly apiServerUrl: string) {}

    protected async resolveHeaders(headers?: object): Promise<object | undefined> {
        return headers;
    }

    protected async resolveUrl(url: string): Promise<string> {
        return this.apiServerUrl + url;
    }

    abstract get<T = any>(url: string, headers?: object, options?: RequestOptions): Promise<T>;
    abstract post<T = any, R = any>(url: string, body: R, headers?: object, options?: RequestOptions): Promise<T>;
    abstract put<T = any, R = any>(url: string, body: R, headers?: object, options?: RequestOptions): Promise<T>;
    abstract delete<T = any>(url: string, headers?: object, options?: RequestOptions): Promise<T>;
}
