import styled from "styled-components";
import { useTranslation } from "@clairejs/react";

import UnsplashImg from "../../components/UnsplashImg";
import defaultBannerImg from "../../../assets/imgs/img-banner.png";
import ThemeButton from "../../components/ThemeButton";
import FilledButton from "../../components/FilledButton";
import { useConfig } from "../../../utils/resolver";
import { Trans } from "../../../assets/translations/Trans";
import { useNavigator } from "@clairejs/react-web";

const Wrapper = styled.div`
    position: relative;
    height: 610px;

    .banner-content {
        width: 100%;
        margin: auto;
        z-index: 1;
        position: absolute;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        box-sizing: border-box;

        .buttons {
            display: flex;
            align-items: center;
            > div {
                margin-right: 13px;
            }
        }
    }

    .title {
        background: linear-gradient(90deg, #5acaeb 0%, #0498c2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: bold;
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.2rem;
        margin-left: 2px;
        margin-bottom: 55px;
    }

    .banner-background {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export default function Banner() {
    const config = useConfig();
    const navigator = useNavigator();

    const t = useTranslation<Trans>();

    return (
        <Wrapper className="banner">
            <div className="banner-content">
                <div className="containerx">
                    <div>
                        <div className="title">ClaireJS Framework</div>
                        <div className="subtitle">{t.home.sub_title()}</div>
                    </div>
                    <div className="buttons">
                        {/* <ThemeButton label={t.home.contact_us()} /> */}
                        <a rel="noreferrer" href="https://doc.clairejs.com" target={"_blank"}>
                            <FilledButton border>{t.home.get_started()}</FilledButton>
                        </a>
                    </div>
                </div>
            </div>
            <UnsplashImg
                className="banner-background"
                keyword="white mac keyboard"
                apiKey={config.UNSPLASH_API_KEY}
                retentionDurationH={24 * 3}
                storageKey="IMG_BANNER"
                orientation="landscape"
                fallbackImg={defaultBannerImg}
            />
        </Wrapper>
    );
}
