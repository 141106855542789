import styled from "styled-components";
import imgLogo from "../../assets/logo.svg";
import LangSwitcher from "../components/LangSwitcher";
import Socials from "../components/Socials";

import imgVi from "../../assets/icons/lang/vi.png";
import imgEn from "../../assets/icons/lang/en.png";

const imgMapping: Record<string, any> = {
    vi: imgVi,
    en: imgEn,
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 34px;
    background-color: transparent;
    position: fixed;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;
    transition: 1s;
    top: 0;

    &.float {
        backdrop-filter: blur(10px);
    }

    .left,
    .right {
        display: flex;
        align-items: center;
    }

    .logo {
        height: 34px;
        margin-right: 21px;
        margin-bottom: 8px;
    }
    .links {
        font-weight: bold;
        font-size: 1rem;
        display: flex;
        align-items: center;
        a {
            padding: 13px;
            color: black;
            text-decoration: none;
            &:hover {
                color: rgba(4, 152, 194, 1);
            }
        }
    }

    .version {
        font-weight: bold;
    }

    .separator {
        color: rgba(0, 0, 0, 0.5);
        margin: 0 34px;
    }

    .lang-switcher {
        margin-left: 34px;

        .lang-drop {
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;

            .flag-icon {
                width: 34px;
                height: 21px;
                border-radius: 5px;
                margin-right: 8px;
            }

            .lang-name {
                font-weight: bold;
                min-width: 21px;
                text-align: right;
            }
        }
    }
`;

interface Props {
    float?: boolean;
}
export default function PageHeader({ float }: Props) {
    return (
        <Wrapper className={`${float ? "float" : ""} page-header`}>
            <div className="left">
                <img className="logo" src={imgLogo} />
                <div className="links">
                    <a target={"_blank"}>Blogs (coming soon)</a>
                    <a target={"_blank"} href={"https://api.clairejs.com"}>
                        <span>API explorer</span>
                    </a>
                </div>
            </div>
            <div className="right">
                <div className="version">Version: 3.x</div>
                <div className="separator">|</div>
                <Socials />
                <LangSwitcher
                    dropdownRenderer={(lang) => (
                        <div className="lang-drop">
                            <img className="flag-icon" src={imgMapping[lang]} />
                            {/* <span className="lang-name">{lang.toUpperCase()}</span> */}
                        </div>
                    )}
                    menuItemRenderer={(lang) => (
                        <div style={{ display: "flex" }}>
                            <img
                                style={{
                                    width: "34px",
                                    height: "21px",
                                    borderRadius: "3px",
                                    marginRight: "8px",
                                }}
                                src={imgMapping[lang]}
                            />
                            <span style={{ minWidth: "34px" }}>{lang.toUpperCase()}</span>
                        </div>
                    )}
                />
            </div>
        </Wrapper>
    );
}
