import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    padding: 9px 21px;
    background: linear-gradient(90deg, #5acaeb 0%, #0498c2 100%);
    cursor: pointer;
    transition-duration: 0.3s;

    font-weight: bold;
    font-size: 1.2rem;
    color: white;
    user-select: none;

    &.bordered {
        border-radius: 50px;
    }

    :hover {
        transform: translateY(-5px);
    }

    :active {
        transform: translateY(-2px);
    }
`;

interface Props {
    onClick?: () => void;
    children?: any;
    border?: boolean;
}

export default function FilledButton({ children, onClick, border }: Props) {
    return (
        <Wrapper className={`${border ? "bordered" : ""} filled-button`} onClick={onClick}>
            {children}
        </Wrapper>
    );
}
