import { Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Translator } from "@clairejs/client";
import { useResolve } from "../../utils/resolver";

const Wrapper = styled.div``;

interface Props {
    dropdownRenderer: (lang: string) => any;
    menuItemRenderer: (lang: string) => any;
}
export default function LangSwitcher({ dropdownRenderer, menuItemRenderer }: Props) {
    const translator = useResolve(Translator);
    const [langs, setLangs] = useState<string[]>([]);
    const [currentLang, setCurrentLang] = useState("");

    useEffect(() => {
        translator.getLanguages().then(setLangs);
        translator.getCurrentLanguage().then((lang) => setCurrentLang(lang || ""));
    }, []);

    return (
        <Wrapper className="lang-switcher">
            {currentLang && (
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <Menu
                            onClick={({ key }) => {
                                translator.setCurrentLanguage(key).then(() => {
                                    setCurrentLang(key);
                                });
                            }}
                            items={langs.map((lang) => ({ key: lang, label: menuItemRenderer(lang) }))}
                        />
                    }>
                    {dropdownRenderer(currentLang)}
                </Dropdown>
            )}
        </Wrapper>
    );
}
