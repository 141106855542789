import axios from "axios";
import { AbstractLogger } from "@clairejs/core";
import { AbstractHttpClient, RequestOptions } from "./AbstractHttpClient";

export class DefaultHttpClient extends AbstractHttpClient {
    private issuedGetRequests: Record<string, boolean> = {};

    constructor(
        readonly apiServerUrl: string,
        readonly logger: AbstractLogger,
        readonly maxRetryCount = 2,
        readonly delayMsBetweenRetry = 200,
    ) {
        super(apiServerUrl);
    }

    protected async retry(apiCall: () => Promise<any>, retryCount: number = 0): Promise<any> {
        try {
            const result = await apiCall();
            return result;
        } catch (err: any) {
            //-- if error is 503 or has empty body then retry
            if (
                (!err.response ||
                    err.response.status === 503 ||
                    err.response.data?.message === "Service Unavailable" ||
                    err.code === "ERR_NETWORK") &&
                retryCount < this.maxRetryCount
            ) {
                this.logger.debug(
                    `unknown error encountered, retrying request ${retryCount + 1}/${this.maxRetryCount} after ${
                        this.delayMsBetweenRetry
                    }ms`,
                );
                await new Promise((resolve) => setTimeout(resolve, this.delayMsBetweenRetry));
                return await this.retry(apiCall, retryCount + 1);
            } else {
                throw err;
            }
        }
    }

    /*
        Keep track off all get request had been sent and allow decache (using regex pattern) those requests
        so next request will not get from cache
     */
    resetCache(pattern: string) {
        const regex = new RegExp(pattern);
        Object.keys(this.issuedGetRequests)
            .filter((key) => key.match(regex))
            .forEach((key) => (this.issuedGetRequests[key] = false));
    }

    async get<T = any>(url: string, headers?: object, options?: RequestOptions): Promise<T> {
        const finalUrl = await this.resolveUrl(url);
        const finalHeaders = await this.resolveHeaders(headers);

        const shouldUseCache = this.issuedGetRequests[finalUrl];
        const result = await this.retry(() =>
            axios.get(finalUrl, {
                headers: { ...finalHeaders, ...(shouldUseCache ? {} : { "cache-control": "no-cache" }) },
                withCredentials: options?.withCredentials,
            }),
        );
        this.issuedGetRequests[finalUrl] = true;
        return result.data;
    }

    async post<T = any, R = any>(url: string, body: R, headers?: object, options?: RequestOptions): Promise<T> {
        const finalUrl = await this.resolveUrl(url);
        const finalHeaders = await this.resolveHeaders(headers);
        const result = await this.retry(() =>
            axios.post(finalUrl, body, { headers: finalHeaders as any, withCredentials: options?.withCredentials }),
        );
        return result.data;
    }

    async put<T = any, R = any>(url: string, body: R, headers?: object, options?: RequestOptions): Promise<T> {
        const finalUrl = await this.resolveUrl(url);
        const finalHeaders = await this.resolveHeaders(headers);
        const result = await this.retry(() =>
            axios.put(finalUrl, body, { headers: finalHeaders as any, withCredentials: options?.withCredentials }),
        );
        return result.data;
    }

    async delete<T = any>(url: string, headers?: object, options?: RequestOptions): Promise<T> {
        const finalUrl = await this.resolveUrl(url);
        const finalHeaders = await this.resolveHeaders(headers);
        const result = await this.retry(() =>
            axios.delete(finalUrl, { headers: finalHeaders as any, withCredentials: options?.withCredentials }),
        );
        return result.data;
    }
}
