import { Register } from "@clairejs/core";
import { AbstractErrorHandler } from "@clairejs/client";

@Register()
export class ErrorHandler extends AbstractErrorHandler {
    handle(err: any, component?: any): void {
        const error = err.response ? err.response.data : err;
        console.log(error);
    }
}
