import styled from "styled-components";
import Features from "../../components/Features";

import imgDo1 from "../../../assets/icons/do-1.svg";
import imgDo2 from "../../../assets/icons/do-2.svg";
import imgDo3 from "../../../assets/icons/do-3.svg";
import imgForDevOps from "../../../assets/imgs/for-devops-bg.png";

const Wrapper = styled.div`
    position: relative;
    .bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -1;
    }
`;

export default function ForDevops() {
    return (
        <Wrapper>
            <img className="bg" src={imgForDevOps} alt="background" />
            <Features
                title="For DevOps engineers"
                items={[
                    {
                        title: "AWS Lambda & ExpressJs compatible",
                        icon: imgDo1,
                        subtitle: "Switch from development to production with ease",
                    },
                    {
                        title: "AWS Gateway Socket & HTTP integration",
                        icon: imgDo2,
                        subtitle: "Build with scale and up-to-date technology in mind",
                    },
                    {
                        title: "Detailed AWS deployment instructions",
                        icon: imgDo3,
                        subtitle: "Detailed step-by-step deployment instructions",
                    },
                ]}
            />
        </Wrapper>
    );
}
