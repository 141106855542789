import ReactDOM from "react-dom";
import {
    AbstractLogger,
    AbstractProvider,
    Configuration,
    ConsoleLogMedium,
    DefaultLogger,
    getServiceProvider,
    LogLevel,
    Provider,
} from "@clairejs/core";
import { DefaultTokenManager, Translator } from "@clairejs/client";
import { ClaireApp } from "@clairejs/react";
import { LocalStorage, ReactWebRoutes } from "@clairejs/react-web";

import "./index.scss";
import { routes } from "./routes";
import { ErrorHandler } from "./utils/ErrorHandler";

//-- setup claire app
const env = process.env.REACT_APP_ENV || "local";

@Provider(Configuration)
class ConfigProvider extends AbstractProvider<Configuration> {
    provide() {
        return Object.assign(new Configuration(), require(`./config/${env}`).default);
    }
}

@Provider(AbstractLogger)
class LoggerProvider extends AbstractProvider<AbstractLogger> {
    provide(): AbstractLogger {
        return new DefaultLogger(LogLevel.DEBUG, [new ConsoleLogMedium()]);
    }
}

const bootstrap = async () => {
    const serviceProvider = getServiceProvider();
    serviceProvider.register(LocalStorage);
    serviceProvider.register(ErrorHandler);
    serviceProvider.register(Translator);
    serviceProvider.register(DefaultTokenManager);
    //-- init client
    const injector = serviceProvider.getInjector();
    const translator = injector.resolve(Translator);
    await injector.initInstances();
    translator.setLanguages(["vi", "en"]);
};

ReactDOM.render(
    <ClaireApp bootstrap={bootstrap} stores={[]}>
        <ReactWebRoutes routeConfig={routes} />
    </ClaireApp>,
    document.getElementById("root") as HTMLElement,
);
