import styled from "styled-components";
import UnsplashImg from "../../components/UnsplashImg";
import imgTypeBg from "../../../assets/imgs/api-explorer-bg.png";

import { useConfig } from "../../../utils/resolver";
import FilledButton from "../../components/FilledButton";

const Wrapper = styled.div`
    padding: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-img {
        width: 377px;
        margin-left: 34px;
    }

    .type-note {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .type-name {
            display: flex;
            align-items: center;

            .type {
                font-weight: bold;
                font-size: 1.5rem;
            }

            img {
                margin-left: 5px;
                margin-bottom: 21px;
                width: 21px;
                height: 21px;
                object-fit: contain;
            }
        }

        .check-out {
            margin-top: 21px;
            .filled-button {
                padding: 5px 13px;
                font-size: 1rem;
            }
            a {
                color: white;
            }
        }

        .type-sub {
            margin-top: 13px;
        }
    }
`;

export default function ApiExplorer() {
    const config = useConfig();

    return (
        <Wrapper className="api-explorer containerx">
            <div className="type-note">
                <div className="type-name">
                    <div className="type">API Explorer</div>
                </div>
                <div className="type-sub">
                    Make your API docs available and update-to-date with ClaireJS API Explorer.
                    <br />
                    <br />
                    Your API docs will be in synced with your implementation without the need of building and serving static
                    HTML content.
                </div>
                <div className="check-out">
                    <a rel="noreferrer" href="https://api.clairejs.com" target={"_blank"}>
                        <FilledButton>Check it out</FilledButton>
                    </a>
                </div>
            </div>
            <UnsplashImg
                enabled={false}
                className="left-img"
                storageKey="IMG_TYPESCRIPT"
                fallbackImg={imgTypeBg}
                retentionDurationH={24}
                apiKey={config.UNSPLASH_API_KEY}
                keyword="programming workspace"
                orientation="landscape"
            />
        </Wrapper>
    );
}
