import { AbstractConstructor, Configuration, getServiceProvider } from "@clairejs/core";
import { useRef } from "react";
import { Config } from "../config/Config";

export const useConfig = () => {
    const ref = useRef(getServiceProvider().getInjector().resolve(Configuration)).current;
    return ref as Config;
};

export const useResolve = <T>(constructorCls: AbstractConstructor<T>): T => {
    const ref = useRef(getServiceProvider().getInjector().resolve(constructorCls)).current;
    return ref as T;
};
