import { AbstractLogger, getServiceProvider } from "@clairejs/core";
import { AbstractStorage, DefaultHttpClient } from "@clairejs/client";
import { useEffect, useRef, useState } from "react";

const unsplashAPIUrl = "https://api.unsplash.com";

interface ImgInfo {
    url: string;
    keyword: string;
    fetchTimestamp: number;
    orientation: string;
}

interface Props {
    enabled?: boolean;
    apiKey: string;
    storageKey: string;
    retentionDurationH: number;
    keyword: string;
    orientation: "landscape" | "portrait" | "squarish";
    fallbackImg: string;
    className?: string;
    unsplashApi?: string;
}

export default function UnsplashImg({
    enabled,
    className,
    storageKey,
    fallbackImg,
    retentionDurationH,
    apiKey,
    keyword,
    orientation,
    unsplashApi,
}: Props) {
    const storage = useRef(getServiceProvider().getInjector().resolve(AbstractStorage)).current;
    const logger = useRef(getServiceProvider().getInjector().resolve(AbstractLogger)).current;
    const httpClient = useRef(new DefaultHttpClient(unsplashApi || unsplashAPIUrl, logger)).current;
    const [img, setImg] = useState(fallbackImg);

    //-- get img from storage
    useEffect(() => {
        (async () => {
            if (enabled) {
                let item = await storage.getItem<ImgInfo>(storageKey);
                if (
                    !item ||
                    item.keyword !== keyword ||
                    item.orientation !== orientation ||
                    item.fetchTimestamp + retentionDurationH * 3600000 < Date.now()
                ) {
                    const info = await httpClient.get(
                        `/photos/random?client_id=${apiKey}&query=${keyword}&orientation=${orientation}`,
                    );
                    //-- set info
                    item = {
                        keyword,
                        orientation,
                        fetchTimestamp: Date.now(),
                        url: info?.urls?.regular || fallbackImg,
                    };
                    await storage.setItem(storageKey, item);
                }
                //-- set image
                setImg(item.url);
            }
        })().catch((err) => console.log(err));
    }, []);

    return <img className={className} src={img} />;
}
