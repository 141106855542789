import { Input } from "antd";
import styled from "styled-components";

import imgSend from "../../assets/icons/send.svg";
import imgMail from "../../assets/icons/mail.svg";
import footerImg from "../../assets/imgs/footer-img.png";
import FilledButton from "../components/FilledButton";
import Socials from "../components/Socials";

const Wrapper = styled.div`
    position: relative;
    .footer-background {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        object-fit: cover;
        z-index: -1;
        position: absolute;
        top: 0;
    }

    .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .cols {
            margin-top: 34px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: stretch;

            .d-column {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                > div {
                    min-height: 144px;
                    width: 100%;
                }
                flex: 1;
                &:last-child {
                    flex: 2;
                }
            }
        }

        .group {
            font-size: 1rem;
            font-weight: bold;
            padding: 3px;
        }
        .item {
            padding: 3px;
            font-weight: normal;
            font-size: 0.8rem;
            color: rgba(0, 0, 0, 0.5);
        }

        .copyright {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 21px;

            .company {
                color: rgba(4, 152, 194, 1);
                text-decoration: none;
            }
        }

        .input-group {
            margin-top: 13px;
            display: flex;

            .filled-button {
                font-size: 0.9rem;
                padding: 5px 13px;
                display: flex;
                align-items: center;
            }

            .img-send {
                width: 21px;
                height: 21px;
                object-fit: contain;
                margin-left: 8px;
            }
        }

        .styled-input {
            flex: 1;
            margin-right: 8px;
            border: 1px solid #5acaeb;
            outline: none;

            &:focus {
                border: 1px solid rgba(4, 152, 194, 1);
            }
        }
    }
`;

export default function PageFooter() {
    return (
        <Wrapper>
            <img className="footer-background" src={footerImg} />
            <div className="footer-content containerx">
                <div className="cols">
                    <div className="d-column">
                        <div>
                            <div className="group">Terms & Conditions</div>
                            <div className="item">Privacy</div>
                            <div className="item">Terms</div>
                            <div className="item">Conditions</div>
                        </div>
                        <div>
                            <div className="group">FAQ</div>
                            <div className="item">Community</div>
                            <div className="item">Questions</div>
                        </div>
                    </div>
                    <div className="d-column">
                        <div>
                            <div className="group">Help & Support</div>
                            <div className="item">Send email</div>
                            <div className="item">Direct chat</div>
                        </div>
                        <div>
                            <div className="group">More</div>
                            <div className="item">Events</div>
                            <div className="item">Report issues</div>
                        </div>
                    </div>
                    <div className="d-column flex-1">
                        <div>
                            <div className="group">Contact Us</div>
                            <Socials />
                        </div>
                        {/* <div>
                            <div className="group">Subscribe for latest events</div>
                            <div className="item">Receive our latest news and patch notes.</div>
                            <div className="input-group">
                                <Input
                                    prefix={
                                        <img
                                            style={{
                                                marginBottom: "2px",
                                                opacity: 0.4,
                                                width: "21px",
                                                marginRight: "8px",
                                            }}
                                            src={imgMail}
                                        />
                                    }
                                    type="email"
                                    placeholder="Enter your email here"
                                    className="styled-input"
                                />
                                <FilledButton>
                                    <div className="flex">
                                        <span>Subscribe</span>
                                        <img className="img-send" src={imgSend} />
                                    </div>
                                </FilledButton>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* <div className="copyright">
                    <span>Copyright © 2002</span>
                    &nbsp;
                    <a href="https://tamidi.com" target={"_blank"} className="company">
                        tamidi
                    </a>
                </div> */}
            </div>
        </Wrapper>
    );
}
